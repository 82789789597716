import { Component } from "react"
import GeoAffiliateLink from "../Components/GeoAffiliateLink";
import GoogleAd from "../Components/GoogleAd";
import Navigation from "../Components/Navigation";

class FizzBuzzKata extends Component {
    render() {
        return (
            <div>
                <Navigation path="katas" />
                <div className="container">
                    <h1>Fizz Buzz Kata</h1>

                    <div className="description">
                        <img src="/img/katas/kata_fizzbuzz.png" alt="Fizz Buzz Kata" align="left" />
                        <p>
                            Fizz Buzz is a counting and number substitution game mainly aimed at teaching children division. Players take turns counting up from one, replacing any number that is divisible by three with the word 'fizz', any number divisible by five with the word 'buzz', and any number divisible by both three and five to be replaced with 'fizz buzz'.
                        </p>
                        <p>
                            You can read more about <a href="https://en.wikipedia.org/wiki/Fizz_buzz" target="_blank" rel="noopener noreferrer">Fizz Buzz</a> on Wikipedia, and if you want to try it out in real life you can buy a book including <GeoAffiliateLink gbLink="https://amzn.to/3pinL8Y" usLink="https://amzn.to/36G0APn" linkDescription="Fizz Buzz" /> and other numeracy games on Amazon.
                        </p>
                    </div>
                    <hr />
                    <div className="features">
                        <div className="feature">
                            <h2>Feature 1 - Basic Fizz Buzz Scoring</h2>
                            <p>
                                You'll be getting used to this by now. Once again, we need a backend for the game that can correctly score the game we're implementing on the front end. It needs to be a reusable library that simply takes a number, and returns the correct answer for that number- whether that is the number itself, 'fizz', 'buzz', or 'fizz buzz'.
                            </p>
                            <div className="row">
                                <div className="card">
                                    <h3>Normal Numbers Return Same Number</h3>
                                    <div className="card-contents">
                                        <p>
                                            As a games designer<br />I want a normal number to return that number<br />So that the game can be scored according to the rules
                                        </p>
                                        <ul>
                                            <li>
                                                Given I have started the game<br />
                                                When I enter $number<br />
                                                Then $result is returned
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="test-cases">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th>Number</th>
                                                    <th>Result</th>
                                                </tr>
                                                <tr>
                                                    <td>1</td>
                                                    <td>1</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>2</td>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>4</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="card">
                                    <h3>Multiples of Three Return Fizz</h3>
                                    <div className="card-contents">
                                        <p>
                                            As a games designer<br />
                                            I want a multiple of three to return 'fizz'<br />
                                            So that the game can be scored according to the rules
                                        </p>
                                        <ul>
                                            <li>
                                                Given I have started the game<br />
                                                When I enter $number<br />
                                                Then $result is returned
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="test-cases">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th>Number</th>
                                                    <th>Result</th>
                                                </tr>
                                                <tr>
                                                    <td>3</td>
                                                    <td>fizz</td>
                                                </tr>
                                                <tr>
                                                    <td>9</td>
                                                    <td>fizz</td>
                                                </tr>
                                                <tr>
                                                    <td>123</td>
                                                    <td>fizz</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="card">
                                    <h3>Multiples of Five Return Buzz</h3>
                                    <div className="card-contents">
                                        <p>
                                            As a games designer<br />
                                            I want a multiple of five to return 'buzz'<br />
                                            So that the game can be scored according to the rules
                                        </p>
                                        <ul>
                                            <li>
                                                Given I have started the game<br />
                                                When I enter $number<br />
                                                Then $result is returned
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="test-cases">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th>Number</th>
                                                    <th>Result</th>
                                                </tr>
                                                <tr>
                                                    <td>5</td>
                                                    <td>buzz</td>
                                                </tr>
                                                <tr>
                                                    <td>20</td>
                                                    <td>buzz</td>
                                                </tr>
                                                <tr>
                                                    <td>200</td>
                                                    <td>buzz</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="card">
                                    <h3>Multiples of Three and Five Return Fizz Buzz</h3>
                                    <div className="card-contents">
                                        <p>
                                            As a games designer<br />
                                            I want a multiple of three and five to return 'fizz buzz'<br />
                                            So that the game can be scored according to the rules
                                        </p>
                                        <ul>
                                            <li>
                                                Given I have started the game<br />
                                                When I enter $number<br />
                                                Then $result is returned
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="test-cases">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th>Number</th>
                                                    <th>Result</th>
                                                </tr>
                                                <tr>
                                                    <td>15</td>
                                                    <td>fizz buzz</td>
                                                </tr>
                                                <tr>
                                                    <td>45</td>
                                                    <td>fizz buzz</td>
                                                </tr>
                                                <tr>
                                                    <td>315</td>
                                                    <td>fizz buzz</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <GoogleAd />
                        <div className="feature">
                            <h2>Feature 2 - Playing FizzBuzz Variations</h2>
                            <p>
                                Turns out that Fizz Buzz has a whole bunch of variants that people play in different countries and for different ages of children. One of the most interesting we have seen is Fizz Buzz Pop, where the word 'pop' is substituted for numbers that are a multiple of seven. We want you to extend the rules of our current Fizz Buzz game to allow players to play Fizz Buzz Pop instead.
                            </p>
                            <div className="row">
                                <div className="card">
                                    <h3>Multiples of Seven Return Pop</h3>
                                    <div className="card-contents">
                                        <p>
                                            As a games designer<br />
                                            I want a multiple of seven to return 'pop'<br />
                                            So that the game can be scored according to the rules
                                        </p>
                                        <ul>
                                            <li>
                                                Given I have started the game<br />
                                                When I enter $number<br />
                                                Then $result is returned
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="test-cases">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th>Number</th>
                                                    <th>Result</th>
                                                </tr>
                                                <tr>
                                                    <td>7</td>
                                                    <td>pop</td>
                                                </tr>
                                                <tr>
                                                    <td>28</td>
                                                    <td>pop</td>
                                                </tr>
                                                <tr>
                                                    <td>77</td>
                                                    <td>pop</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="card">
                                    <h3>Multiples of Three and Seven Return Fizz Pop</h3>
                                    <div className="card-contents">
                                        <p>
                                            As a games designer<br />
                                            I want a multiple of three and seven to return 'fizz pop'<br />
                                            So that the game can be scored according to the rules
                                        </p>
                                        <ul>
                                            <li>
                                                Given I have started the game<br />
                                                When I enter $number<br />
                                                Then $result is returned
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="test-cases">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th>Number</th>
                                                    <th>Result</th>
                                                </tr>
                                                <tr>
                                                    <td>21</td>
                                                    <td>fizz pop</td>
                                                </tr>
                                                <tr>
                                                    <td>63</td>
                                                    <td>fizz pop</td>
                                                </tr>
                                                <tr>
                                                    <td>126</td>
                                                    <td>fizz pop</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="card">
                                    <h3>Multiples of Five and Seven Return Buzz Pop</h3>
                                    <div className="card-contents">
                                        <p>
                                            As a games designer<br />
                                            I want a multiple of five and seven to return 'buzz pop'<br />
                                            So that the game can be scored according to the rules
                                        </p>
                                        <ul>
                                            <li>
                                                Given I have started the game<br />
                                                When I enter $number<br />
                                                Then $result is returned
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="test-cases">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th>Number</th>
                                                    <th>Result</th>
                                                </tr>
                                                <tr>
                                                    <td>35</td>
                                                    <td>buzz pop</td>
                                                </tr>
                                                <tr>
                                                    <td>70</td>
                                                    <td>buzz pop</td>
                                                </tr>
                                                <tr>
                                                    <td>140</td>
                                                    <td>buzz pop</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="card">
                                    <h3>Multiples of Three, Five and Seven Return Fizz Buzz Pop</h3>
                                    <div className="card-contents">
                                        <p>
                                            As a games designer<br />
                                            I want a multiple of three, five and seven to return 'fizz buzz pop'<br />
                                            So that the game can be scored according to the rules
                                        </p>
                                        <ul>
                                            <li>
                                                Given I have started the game<br />
                                                When I enter $number<br />
                                                Then $result is returned
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="test-cases">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th>Number</th>
                                                    <th>Result</th>
                                                </tr>
                                                <tr>
                                                    <td>105</td>
                                                    <td>fizz buzz pop</td>
                                                </tr>
                                                <tr>
                                                    <td>210</td>
                                                    <td>fizz buzz pop</td>
                                                </tr>
                                                <tr>
                                                    <td>315</td>
                                                    <td>fizz buzz pop</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <GoogleAd />
                        <div className="feature">
                            <h2>Feature 3 - Creating Fizz Buzz Variations</h2>
                            <p>
                                So not that many people have actually heard of or played Fizz Buzz Pop, probably because the substitution words are different in different countries across the world. We're going to give players the ability to create their own substitution words and link them to multiples of certain numbers, and we need you to make this possible.
                            </p>
                            <div className="row">
                                <div className="card">
                                    <h3>Using a Custom Substitution</h3>
                                    <div className="card-contents">
                                        <p>
                                            As a player<br />
                                            I want to be able to choose my own substitutions<br />
                                            So that I can tailor the game to my preferences
                                        </p>
                                        <ul>
                                            <li>
                                                Given I have substituted multiples of two for 'fuzz'<br />
                                                When I enter $number<br />
                                                Then $result is returned
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="test-cases">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th>Number</th>
                                                    <th>Result</th>
                                                </tr>
                                                <tr>
                                                    <td>1</td>
                                                    <td>1</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>fuzz</td>
                                                </tr>
                                                <tr>
                                                    <td>8</td>
                                                    <td>fuzz</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="card">
                                    <h3>Linking Custom Substitutions Together</h3>
                                    <div className="card-contents">
                                        <p>
                                            As a player<br />
                                            I want my substitutions to work the same way as Fizz Buzz<br />
                                            So that the essence of the game remains the same
                                        </p>
                                        <ul>
                                            <li>
                                                Given I have substituted multiples of two for 'fuzz'<br />
                                                And I have substituted multiples of three for 'bizz'<br />
                                                When I enter $number<br />
                                                Then $result is returned
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="test-cases">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th>Number</th>
                                                    <th>Result</th>
                                                </tr>
                                                <tr>
                                                    <td>4</td>
                                                    <td>fuzz</td>
                                                </tr>
                                                <tr>
                                                    <td>9</td>
                                                    <td>bizz</td>
                                                </tr>
                                                <tr>
                                                    <td>12</td>
                                                    <td>fuzz bizz</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row katas" />
            </div>
        );
    }
}

export default FizzBuzzKata;
