function Icons(props) {
    return (
        <div className="icons">
            <a href="https://github.com/AgileKatas" target="_blank" rel="noreferrer" id="github">
                <img src="/img/icons/icon_soc_1.png" alt="Agile Katas Github" width={64} />
            </a>
            &nbsp;
            <a href="https://www.facebook.com/agilekatas" target="_blank" rel="noreferrer" id="facebook">
                <img src="/img/icons/icon_soc_2.png" alt="Agile Katas Facebook" width={64} />
            </a>
            &nbsp;
            <a href="https://twitter.com/agilekatas" target="_blank" rel="noreferrer" id="twitter">
                <img src="/img/icons/icon_soc_3.png" alt="Agile Katas Twitter" width={64} />
            </a>
        </div>
    );
}

export default Icons;
