import Navigation from "../Components/Navigation";

function Learn() {
    return (
        <div>
            <Navigation path="learn" />
            <div className="container">
                <h1>Learn</h1>
                <p>
                    Our learning module is currently under active development. For like... the past 7 years.
                </p>
                <p>
                    Please check back later.
                </p>
            </div>
            <div className="row learn" />
        </div>
    );
}

export default Learn;
