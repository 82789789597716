import './App.css';
import { Component } from "react"
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Home from './Pages/Home';
import Katas from './Pages/Katas';
import BerlinClockKata from './Katas/BerlinClockKata';
import BingoKata from './Katas/BingoKata';
import ConnectFourKata from './Katas/ConnectFourKata';
import FizzBuzzKata from './Katas/FizzBuzzKata';
import RockPaperScissorsKata from './Katas/RockPaperScissorsKata';
import RomanNumeralsKata from './Katas/RomanNumeralsKata';
import SnakesAndLaddersKata from './Katas/SnakesAndLaddersKata';
import TamagotchiKata from './Katas/TamagotchiKata';
import TennisKata from './Katas/TennisKata';
import Learn from './Pages/Learn';
import About from './Pages/About';
import Contact from './Pages/Contact';
import ReactGA from 'react-ga4';
import ScrollToTop from './Components/ScrollToTop';
import Privacy from './Pages/Privacy';

class App extends Component {
  useEffect() {
    ReactGA.send(window.location.pathname + window.location.search);
  }
  render() {
    return (
      <BrowserRouter>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/katas" element={<Katas />} />
            <Route path="/katas/BerlinClock-Kata" element={<BerlinClockKata />} />
            <Route path="/katas/Bingo-Kata" element={<BingoKata />} />
            <Route path="/katas/ConnectFour-Kata" element={<ConnectFourKata />} />
            <Route path="/katas/FizzBuzz-Kata" element={<FizzBuzzKata />} />
            <Route path="/katas/RockPaperScissors-Kata" element={<RockPaperScissorsKata />} />
            <Route path="/katas/RomanNumerals-Kata" element={<RomanNumeralsKata />} />
            <Route path="/katas/SnakesAndLadders-Kata" element={<SnakesAndLaddersKata />} />
            <Route path="/katas/Tamagotchi-Kata" element={<TamagotchiKata />} />
            <Route path="/katas/Tennis-Kata" element={<TennisKata />} />
            <Route path="/learn" element={<Learn />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about/privacy" element={<Privacy />} />
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    );
  }
}

export default App;
