import { Component } from "react"

class GoogleAd extends Component {
    componentDidMount() {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
    render() {
        return (
            <div className="ads">
                <ins className="adsbygoogle"
                    style={{ display: "block" }}
                    data-ad-client="ca-pub-5205110041821805"
                    data-ad-slot="5912700779"
                    data-ad-format="auto"
                    data-full-width-responsive="true"></ins>
            </div>
        )
    }
}

export default GoogleAd;
