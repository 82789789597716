import { NavLink } from 'react-router-dom'

function Navigation(props) {
    return (
        <nav id="navigation" className={`navigation ${props.path}`}>
            <div className="container">
                <ul>
                    <li>
                        <NavLink to='/' className={({ isActive }) => isActive ? 'active' : ''}>Agile Katas</NavLink>
                    </li>
                    <li>
                        <NavLink to='/katas' className={({ isActive }) => isActive ? 'active' : ''}>Katas</NavLink>
                    </li>
                    <li>
                        <NavLink to='/learn' className={({ isActive }) => isActive ? 'active' : ''}>Learn</NavLink>
                    </li>
                    <li>
                        <NavLink to='/about' className={({ isActive }) => isActive ? 'active' : ''}>About</NavLink>
                    </li>
                    <li>
                        <NavLink to='/contact' className={({ isActive }) => isActive ? 'active' : ''}>Contact</NavLink>
                    </li>
                </ul>
            </div>
        </nav>
    );
}

export default Navigation;
