import Icons from "../Components/Icons";
import Navigation from "../Components/Navigation";

function Contact() {
    return (
        <div>
            <Navigation path="contact" />
            <div className="container">
                <h1>Contact</h1>
                <h2>Email</h2>
                <p>
                    If you want to get in touch with us directly, you can send an email to <a href="mailto:contact@agilekatas.co.uk">contact@agilekatas.co.uk</a> and someone will probably read it. 
                </p>
                <h2>Social Media</h2>
                <p>
                    Alternatively, you can get in contact with us on our social media platforms.
                </p>
                <Icons />
            </div>
            <div className="row contact" />
        </div>
    );
}

export default Contact;
