import { Link } from "react-router-dom";
import Navigation from "../Components/Navigation";

function About() {
    return (
        <div>
            <Navigation path="about" />
            <div className="container">
                <h1>About</h1>
                <h2>What is Agile?</h2>
                <p>
                    Agile is a software methodology based on iterative development of software where requirements and solutions evolve over time through collaboration. It is a time-boxed iterative approach which encourages flexible responses to change and promotes adaptive planning, evolving development and continuous delivery. The Agile Manifesto introduced a set of four values that best describe agile software practices.
                </p>
                <blockquote>
                    <p>Individuals and interactions over processes and tools<br />Working software over comprehensive documentation<br />Customer collaboration over contract negotiation<br />Responding to change over following a plan
                    </p>
                    <small>
                        <a href="className=http://agilemanifesto.org/" target="_blank" rel="noreferrer">The Agile Manifesto</a>
                    </small>
                </blockquote>
                <p>
                    Agile methodologies come in many different flavours, each of which implement agile values in different ways. Our flavour of choice is <a href="className=https://en.wikipedia.org/wiki/Scrum_(development)" target="_blank" rel="noreferrer">Scrum</a>, and as such we use user stories,  acceptance criteria, and test-driven development to plan and complete our katas.
                </p>
                <h2>What are Coding Katas?</h2>
                <p>
                    The term 'code kata' was coined by <a href="className=http://www.codekata.com/" target="_blank" rel="noreferrer">Dave Thomas</a> and refers to an exercise in programming designed to hone programming skills through the use of practice and repetition. Katas are usually small programming exercises that can be completed in an hour or so, specifically designed to improve your skills in one or more small ways. They can focus on improving muscle memory, getting used to a new language or development environment, practicing thinking about problems in a logical way and breaking them down into constituent parts, trying things in a different way to see what happens, using tests to drive out your design, and much more.
                </p>
                <h2>So, What are Agile Katas?</h2>
                <p>
                    Many programming katas are designed around a very simple exercise, like string manipulation, rules impementation, math problems and such like. These small, simple exercises are great for doing the things that katas do best, completing them quickly and easily in many novel ways can benefit in all the ways mentioned before. However, these small exercises can often seem trite or contrived to developers that are new to TDD and other methodologies and don't always easily map on to real world problems.
                </p>
                <p>
                    As such, our aim is to try to preserve what is the essence of a coding kata- a small exercise to hone skills, and turn them into something just a little bit more. A series of 'katas' that build on from one another over the course of iterations that can hopefully more easily demonstrate changing business needs, the benefits of Test Driven Development and extensible, readable and maintainable code.
                </p>
                <h2>Legal Stuff</h2>
                <p>
                    There's some legal stuff we need to have too- like a link to our <Link to="privacy">Privacy Policy</Link> and... that's probably it?
                </p>
            </div>

            <div className="row about" />
        </div>
    );
}

export default About;
