import { Link } from "react-router-dom";
import Navigation from "../Components/Navigation";

function Katas() {
    return (
        <div>
            <Navigation path="katas" />
            <div className="container">
                <h1>Katas</h1>
                <p>
                    Welcome to Agile Katas! We are an Agile software house and we have our developers working on our software projects using a project backlog. Projects are already split into features, which contain a certain number of user stories, each with acceptance criteria.
                </p>
                <p>
                    Projects are platform and technology agnostic, so you'll generally just be building the barebones backend of any particular project in a modular manner, so we can plug these in to any frontend framework on any technological platform that we desire. Feel free to us any sort of input you are most comfortable with (or want to learn!) whilst you are working on the projects for testing purposes, whether that's a command line interface, Swing components, a HTML5 website or anything else you can think of.
                </p>
                <div className="row">
                    <Link to="/katas/BerlinClock-Kata" className="kata" id="berlinclock-kata">
                        <img src="/img/katas/kata_berlinclock.png" alt="Berlin Clock Kata" />
                        <h2>Berlin Clock Kata</h2>
                        <p>
                            Create a program for converting the time between a regular digital time and a textual representation of the Berlin Clock. Then write another converter to change it back again.
                        </p>
                    </Link>
                    <Link to="/katas/Bingo-Kata" className="kata" id="bingo-kata">
                        <img src="/img/katas/kata_bingo.png" alt="Bingo Kata" />
                        <h2>Bingo Kata</h2>
                        <p>
                            Make a game of Bingo, which can generate Bingo cards, call numbers, and check winning tickets. Don't forget to prepare for the international version!
                        </p>
                    </Link>
                </div>
                <div className="row">
                    <Link to="/katas/ConnectFour-Kata" className="kata" id="connectfour-kata">
                        <img src="/img/katas/kata_connectfour.png" alt="Connect Four Kata" />
                        <h2>Connect Four Kata</h2>
                        <p>
                            Create a Connect Four game- place tokens in a grid to be the first player to connect four of them together to win!
                        </p>
                    </Link>
                    <Link to="/katas/FizzBuzz-Kata" className="kata" id="fizzbuzz-kata">
                        <img src="/img/katas/kata_fizzbuzz.png" alt="Fizz Buzz Kata" />
                        <h2>Fizz Buzz Kata</h2>
                        <p>
                            Write an extended game version of Fizz Buzz, which will have an extended rule set and user customisable rules for playing.
                        </p>
                    </Link>
                </div>
                <div className="row">
                    <Link to="/katas/RockPaperScissors-Kata" className="kata" id="rockpaperscissors-kata">
                        <img src="/img/katas/kata_rockpaperscissors.png" alt="Rock Paper Scissors Kata" />
                        <h2>Rock Paper Scissors Kata</h2>
                        <p>
                            Create a Rock Paper Scissors game, which can be played with a number of rule sets and against both human and computer controlled players. Can you make an unbeatable AI?
                        </p>
                    </Link>
                    <Link to="/katas/RomanNumerals-Kata" className="kata" id="romannumerals-kata">
                        <img src="/img/katas/kata_romannumerals.png" alt="Roman Numerals Kata" />
                        <h2>Roman Numerals Kata</h2>
                        <p>
                            Write a Roman numeral converter to take a standard Arabic number and convert it to a Roman numeral. Then write another converter that will do the opposite.
                        </p>
                    </Link>
                </div>
                <div className="row">
                    <Link to="/katas/SnakesAndLadders-Kata" className="kata" id="snakesandladders-kata">
                        <img src="/img/katas/kata_snakesandladders.png" alt="Snakes and Ladders Kata" />
                        <h2>Snakes and Ladders Kata</h2>
                        <p>
                            Create Snakes And Ladders, a classic board game involving 2 or more players, who roll dice in order to get to the top of the board. Ladders help you climb, snakes do the opposite.
                        </p>
                    </Link>
                    <Link to="/katas/Tamagotchi-Kata" className="kata" id="tamagotchi-kata">
                        <img src="/img/katas/kata_tamagotchi.png" alt="Tamagotchi Kata" />
                        <h2>Tamagotchi Kata</h2>
                        <p>
                            Create a Tamagotchi game, where you have a pet that you can feed, play with and clean up after. Move on to add variety of pets, food and games to keep things interesting.
                        </p>
                    </Link>
                </div>
                <div className="row">
                    <Link to="/katas/Tennis-Kata" className="kata" id="tennis-kata">
                        <img src="/img/katas/kata_tennis.png" alt="Tennis Kata" />
                        <h2>Tennis Kata</h2>
                        <p>
                            Create a tennis scoring program that we can use to provide real time score updates for a variety of uses- games, competitions, television and more.
                        </p>
                    </Link>
                </div>
            </div>
            <div className="row katas" />
        </div>
    );
}

export default Katas;
