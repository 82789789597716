import { Component } from "react"
import GeoAffiliateLink from "../Components/GeoAffiliateLink";
import GoogleAd from "../Components/GoogleAd";
import Navigation from "../Components/Navigation";

class TamagotchiKata extends Component {
    render() {
        return (
            <div>
                <Navigation path="katas" />

                <div className="container">
                    <h1>Tamagotchi Kata</h1>
                    <div className="description">
                        <img src="/img/katas/kata_tamagotchi.png" alt="Tamagotchi Kata" align="left" />
                        <p>
                            A Tamagotchi is a small, handheld digital pet that you can feed, play with, put to bed and clean up after. Look after it well by feeding it the right kinds of foods, showering it with attention and cleaning up after it when required, and your Tamagotchi will grow up to be a smart, well-respected member of society.
                        </p>
                        <p>
                            To aid the quick release of this project, we require you to deliver the absolute minimum that could reasonably be called a Tamagotchi pet as soon as possible. Then we're going to add all of the good stuff- different foods and games to play, all purchasable with our own very special currency, the Kablammo.
                        </p>
                        <p>
                            You can read more about the history of the <a href="https://en.wikipedia.org/wiki/Tamagotchi" target="_blank" rel="noopener noreferrer">Tamagotchi</a> on Wikipedia, and you will definitely want a real-life <GeoAffiliateLink gbLink="https://amzn.to/33YkDrv" usLink="https://amzn.to/3K5H9y7" linkDescription="Tamagotchi" /> which are available on Amazon.
                        </p>
                    </div>
                    <hr />
                    <div className="features">
                        <div className="feature">
                            <h2>Feature 1 - Basic Needs</h2>
                            <p>
                                Like we said before, first things first is to get all of the basic needs finished so we have some semblance of a basic Tamagotchi pet. We're talking about things like hungriness, fullness, tiredness, happiness and of course, the actions required to mitigate these needs. We're not really sure of the implementation though. We were thinking of needs on a scale of 1-100, with different activities having different effects on them, but we'll go with whatever you think is best.
                            </p>
                            <div className="row">
                                <div className="card">
                                    <h3>Feeding Tamagotchi</h3>
                                    <div className="card-contents">
                                        <p>
                                            As a Tamagotchi owner<br />
                                            I want to feed my Tamagotchi<br />
                                            So that I can sate it's hunger
                                        </p>
                                        <ul>
                                            <li>
                                                Given I have a Tamagotchi<br />
                                                When I feed it<br />
                                                Then it's hungriness is decreased<br />
                                                And it's fullness is increased
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="card">
                                    <h3>Playing With Tamagotchi</h3>
                                    <div className="card-contents">
                                        <p>
                                            As a Tamagotchi owner<br />
                                            I want to play with my Tamagotchi<br />
                                            So that I can make it happier
                                        </p>
                                        <ul>
                                            <li>
                                                Given I have a Tamagotchi<br />
                                                When I play with it<br />
                                                Then it's happiness is increased<br />
                                                And it's tiredness is increased
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="card">
                                    <h3>Putting Tamagotchi to Bed</h3>
                                    <div className="card-contents">
                                        <p>
                                            As a Tamagotchi owner<br />
                                            I want to put my Tamagotchi to bed<br />
                                            So that I can refill it's energy
                                        </p>
                                        <ul>
                                            <li>
                                                Given I have a Tamagotchi<br />
                                                When I put it to bed<br />
                                                Then it's tiredness is decreased
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="card">
                                    <h3>Making Tamagotchi Poop</h3>
                                    <div className="card-contents">
                                        <p>
                                            As a Tamagotchi owner<br />
                                            I want to make my Tamagotchi poop<br />
                                            So that it is more comfortable
                                        </p>
                                        <ul>
                                            <li>
                                                Given I have a Tamagotchi<br />
                                                When I make it poop<br />
                                                Then it's fullness is decreased
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="card">
                                    <h3>Changing Tamagotchi Needs Over Time</h3>
                                    <div className="card-contents">
                                        <p>
                                            As a Tamagotchi owner<br />
                                            I want my Tamagotchi's needs to change over time<br />
                                            So that I have to look after it carefully
                                        </p>
                                        <ul>
                                            <li>
                                                Given I have a Tamagotchi<br />
                                                When time passes<br />
                                                Then it's tiredness is increased<br />
                                                And it's hungriness is increased<br />
                                                And it's happiness is decreased
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <GoogleAd />
                    </div>
                </div>
                <div className="row katas" />
            </div>
        );
    }
}

export default TamagotchiKata;
